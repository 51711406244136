import gsap from 'gsap'
import { easeOutQuad } from '../../utils/constants/eases'
import { gsapParams } from '../../utils/page-animations/common'

const selectors = {
  wrapper: '.tpl-about__team',
  listRow: '.tpl-about__team .row',
  listItem: '.tpl-about__team li',
  listTitle: '.tpl-about__team .list-title',
}

const hidePersonImage = ({ element, onComplete }) => {
  const tl = gsap.timeline({
    onComplete: onComplete ? onComplete : () => {},
  })
  tl.to(element, { opacity: 0, ease: easeOutQuad, duration: 0.15 }, 0)
  tl.to(element, { position: 'absolute', duration: 0 }, 0)

  return tl
}

const showPersonImage = ({ element }) => {
  const tl = gsap.timeline()
  tl.to(element, { position: 'fixed', duration: 0 }, 0)
  tl.to(element, { opacity: 1, ease: easeOutQuad, duration: 0.15 })

  return tl
}

const moveImgToMouse = ({ event, element }) => {
  if (!element) {
    return
  }
  gsap.to(element, {
    left: event.clientX - element.clientWidth / 2,
    top: event.clientY + 48,
    position: 'fixed',
    ease: 'power4.easeOut',
  })
}

const setSectionEntry = (show) => {
  gsap.set(
    selectors.listItem,
    show ? gsapParams.showInUp : { opacity: 0, translateY: '20%' }
  )
}

const animateSectionEntry = ({ onComplete }) => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.wrapper,
      start: 'top 80%',
      end: '30% center',
      once: true,
    },
    onComplete: onComplete ? onComplete : () => {},
  })
  tl.to(selectors.listItem, {
    ...gsapParams.fadeInUp,
    stagger: {
      each: 0.05,
      grid: 'auto',
      axis: 'y',
    },
  })

  return tl
}

export {
  showPersonImage,
  hidePersonImage,
  moveImgToMouse,
  animateSectionEntry,
  setSectionEntry,
}
