import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import { animateSectionEntry, animateSectionContentEntry } from './animation'

const SectionAbout = ({ number, title, description }) => {
  const [opacity, setOpacity] = useState(0.2)
  const refSection = useRef(null)
  const ticking = useRef(false)
  const [canShowContent, setCanShowContent] = useState(false)

  const handleCalculateOpacity = useCallback((windowInnerHeight, elSection) => {
    const position = elSection.getBoundingClientRect()
    const positionWindowHeightCenter = windowInnerHeight / 2
    let positionElementCenter = position.top + position.height / 2
    if (positionElementCenter < 0) positionElementCenter = 0
    if (positionElementCenter > windowInnerHeight)
      positionElementCenter = windowInnerHeight

    const absCenterDivValue = Math.abs(
      positionWindowHeightCenter - positionElementCenter
    )
    const coeff = (absCenterDivValue * 100) / positionWindowHeightCenter

    let opacity = 1 - coeff / 100
    if (opacity > 1) opacity = 1
    if (opacity < 0.3) opacity = 0.3
    setOpacity(opacity)
  }, [])
  const handleScrollContent = useCallback(() => {
    const elSection = refSection.current
    if (!elSection) return
    if (!ticking.current) {
      window.requestAnimationFrame(() => {
        handleCalculateOpacity(window.innerHeight, elSection)
        ticking.current = false
      })
    }
    ticking.current = true
  }, [handleCalculateOpacity])

  useEffect(() => {
    window.addEventListener('scroll', handleScrollContent)
    setTimeout(handleScrollContent, 200)
    return () => {
      window.removeEventListener('scroll', handleScrollContent)
    }
  }, [handleScrollContent])

  useEffect(() => {
    const tl = animateSectionEntry({
      onComplete: () => {
        setCanShowContent(true)
      },
    })
    return () => tl && tl.kill()
  }, [])

  useEffect(() => {
    if (!canShowContent) {
      return
    }
    const tl = animateSectionContentEntry()
    return () => tl && tl.kill()
  }, [canShowContent])

  const styleOpacity = { opacity }
  return (
    <div className="section-about row" ref={refSection}>
      <div className="section-about__body">
        <div className="section-about__title col col-md-6 col-lg-9 col-lg-offset-1">
          <p className="hide-desktop mb-3" style={styleOpacity}>
            0{number}
          </p>
          <h2 className="ft-500 c-gray-600 mb-3 mb-md-0" style={styleOpacity}>
            {title}
          </h2>
        </div>
        <div className="section-about__content col col-md-6 col-lg-3">
          <span className="hide-mobile" style={styleOpacity}>
            0{number}
          </span>
          <p className="ft-secondary mt-md-1 mt-lg-2 mb-0" style={styleOpacity}>
            {description}
          </p>
        </div>
      </div>
    </div>
  )
}

export default memo(SectionAbout)
