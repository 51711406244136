import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import ClassicLayout from '../components/Layouts/ClassicLayout'
import Statement from '../components/Statement'
import SectionAbout from '../components/SectionAbout'
import ListTeam from '../components/ListTeam'
import JoinUs from '../components/JoinUs/JoinUs'

import { getPrismicEdges } from '../utils/helpers/transformers'
import { graphql } from 'gatsby'

import {
  animateEntry,
  setEntry,
  animateScrollEntry,
  setScrollEntry,
} from '../utils/page-animations/about'
import useAnimationState from '../utils/hooks/useAnimationState'

import ScrollTrigger from 'gsap/ScrollTrigger'
import { shuffleArray } from '../utils/helpers/utils'
import { getRoutesByLang, routeNames } from '../utils/helpers/routes'
import ListPerson from '../components/ListPerson/ListPerson'

const About = (props) => {
  const { data, uri, pageContext } = props
  const { routeName, language } = pageContext
  const [shuffledListTeam, setShuffledListTeam] = useState([])
  const localizedRoutes = getRoutesByLang(language)

  // INTN
  const { t } = useTranslation(['about'])
  const sections = t('about:sections', { returnObjects: true }) || []

  // ANIMATION
  const animationState = useAnimationState({ routeName })

  // REF
  const $hasRunAnimation = useRef(false)

  // DATA
  const pageData = useMemo(() => getPrismicEdges(data, 'page'), [data])

  const partners = useMemo(() => {
    return shuffledListTeam.filter((person) => person.data.team === 'partner')
  }, [shuffledListTeam])

  const designTeam = useMemo(() => {
    return shuffledListTeam.filter((person) => person.data.team === 'design')
  }, [shuffledListTeam])

  const techTeam = useMemo(() => {
    return shuffledListTeam.filter((person) => person.data.team === 'tech')
  }, [shuffledListTeam])

  const supportTeam = useMemo(() => {
    return shuffledListTeam.filter((person) => person.data.team === 'admin')
  }, [shuffledListTeam])

  // HOOKS
  useEffect(() => {
    ScrollTrigger.clearScrollMemory()
    setEntry()
    setScrollEntry()
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animationState.canStart && animationState.isReady) {
      $hasRunAnimation.current = true
      setEntry(true)
      setScrollEntry(true)
      return
    }
  }, [animationState.canStart, animationState.isReady])

  useEffect(() => {
    if (!animationState.canPlay || $hasRunAnimation.current) {
      return
    }

    const tl = animateEntry()
    const scrollTl = animateScrollEntry()

    $hasRunAnimation.current = true
    return () => {
      scrollTl && scrollTl.forEach((t) => t && t.kill())
      tl && tl.forEach((t) => t && t.kill())
    }
  }, [animationState.canPlay])

  useEffect(() => {
    setShuffledListTeam(shuffleArray(pageData))
  }, [pageData])

  return (
    <ClassicLayout
      name={routeName}
      language={language}
      uri={uri}
      seo={{
        title: t('about:seo:title'),
        description: t('about:seo:description'),
      }}
      internationalRoute={pageContext.internationalRoute}
    >
      <section className="tpl-about__hero">
        <div className="container">
          <div className="row">
            <div className="col col-md-10 col-lg-10 col-lg-offset-1">
              <p className="tpl-about__alias hide-desktop ft-default mb-3 mb-lg-0">
                {t('about:hero:subtitle')}
              </p>
              <h1
                className="tpl-about__title h4 ft-500 c-alpha-0 ft-no-select"
                aria-label={t('about:hero:title')}
              >
                {t('about:hero:title')}
              </h1>
            </div>
          </div>
        </div>
      </section>
      <section className="tpl-about__section-about">
        <div className="container bg-before-gray-400">
          {sections?.length > 0 &&
            sections.map((section, index) => (
              <SectionAbout
                key={index}
                number={index + 1}
                title={section.title}
                description={section.description}
              />
            ))}
        </div>
      </section>
      <section className="tpl-about__statement">
        <div className="container">
          <Statement
            animation={animationState}
            classNameTitle="col-md-4 col-lg-3 col-lg-offset-1"
            title={t('about:hero:subtitle')}
            desc={t('about:description')}
          />
        </div>
      </section>
      <section className="tpl-about__team" id="partners">
        <div className="container">
          <div className="row ">
            <div className="col col-md-4 col-lg-3 col-lg-offset-1">
              <p className="ft-default ft-400">{t('about:title_partners')}</p>
            </div>
            <ul className="col col-md-8 col-lg-9 list-person list-person--grid">
              {partners.map((partner) => (
                <ListPerson person={partner.data} key={partner.id} />
              ))}
            </ul>
          </div>
        </div>
      </section>
      <section className="tpl-about__team" id="design">
        <div className="container">
          <div className="row ">
            <div className="col col-md-4 col-lg-3 col-lg-offset-1">
              <p className="ft-default ft-400">
                {t('about:title_design_team')}
              </p>
            </div>
            <div className="col col-md-8 col-lg-9">
              <ListTeam team={designTeam} animation={animationState} />
            </div>
          </div>
        </div>
      </section>
      <section className="tpl-about__team" id="tech">
        <div className="container">
          <div className="row ">
            <div className="col col-md-4 col-lg-3 col-lg-offset-1">
              <p className="ft-default ft-400">{t('about:title_tech_team')}</p>
            </div>
            <div className="col col-md-8 col-lg-9">
              <ListTeam team={techTeam} animation={animationState} />
            </div>
          </div>
        </div>
      </section>
      <section className="tpl-about__team" id="support">
        <div className="container">
          <div className="row ">
            <div className="col col-md-4 col-lg-3 col-lg-offset-1">
              <p className="ft-default ft-400">{t('about:title_admin_team')}</p>
            </div>
            <div className="col col-md-8 col-lg-9">
              <ListTeam team={supportTeam} animation={animationState} />
            </div>
          </div>
        </div>
      </section>
      <section className="tpl-home__careers">
        <JoinUs
          ctaLink={localizedRoutes[routeNames.careers]}
          animation={animationState}
        />
      </section>
    </ClassicLayout>
  )
}

export default About

export const query = graphql`
  query ($language: String, $contentLang: String) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    page: allPrismicPerson(filter: { lang: { eq: $contentLang } }) {
      ...prismicPerson
    }
  }
`
