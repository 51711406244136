import gsap from 'gsap'
import { easeOutQuad } from '../../utils/constants/eases'
import { breakpoints } from '../../utils/helpers/utils'
import { gsapParams } from '../../utils/page-animations/common'

const selectors = {
  wrapper: '#join-us',
  title: '.join-us__title',
  desc: '.join-us__desc',
  cta: '.join-us__cta',
  img: '.join-us__img',
  imgMobile: '.join-us__img-mobile',
  mosaic: '.join-us__mosaic div',
}

const setEntry = () => {
  gsap.set(selectors.wrapper, { scaleX: 0 })
  gsap.set(
    [
      selectors.title,
      selectors.desc,
      selectors.cta,
      selectors.cta,
      selectors.img,
      selectors.mosaic,
      selectors.imgMobile,
    ],
    gsapParams.fadeOut
  )
  gsap.set([selectors.desc], gsapParams.fadeOutDown30)
}

const animateEntry = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.wrapper,
      start: 'top 80%',
      once: true,
    },
  })

  const isMobile = window.innerWidth < breakpoints.md

  tl.to(selectors.wrapper, { scaleX: 1, duration: 0.6, ease: easeOutQuad }, 0)
  tl.to(selectors.title, { ...gsapParams.fadeIn }, 0.6)
  tl.to(selectors.desc, { ...gsapParams.fadeInUp }, isMobile ? 0.9 : 0.6)

  tl.to(selectors.cta, { ...gsapParams.fadeIn }, isMobile ? 0.6 : 0.8)
  tl.to(selectors.img, { ...gsapParams.fadeIn }, isMobile ? 1.2 : 0.8)
  tl.to(
    selectors.mosaic,
    { ...gsapParams.fadeIn, stagger: 0.1 },
    isMobile ? 1.2 : 0.8
  )
  tl.to(
    selectors.imgMobile,
    { ...gsapParams.fadeIn, stagger: 0.05 },
    isMobile ? 1.2 : 0.8
  )

  return tl
}

export { setEntry, animateEntry }
