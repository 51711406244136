import React, {
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import useBreakpoints from '../../utils/hooks/useBreakpoints'
import { PrismicBreakpointPicture } from '../PrismicPicture'
import {
  moveImgToMouse,
  animateSectionEntry,
  hidePersonImage,
  showPersonImage,
  setSectionEntry,
} from './animation'

import ScrollTrigger from 'gsap/ScrollTrigger'

const ListTeam = ({ team, animation }) => {
  const debounceRef = useRef(null)
  const ticking = useRef(false)
  const $personImage = useRef()
  const timelines = useRef({
    show: null,
    hide: null,
  })

  const [isReadyForHover, setIsReadyForHover] = useState(false)
  const [hoveredPersonId, setHoveredPersonId] = useState(null)
  const { bp } = useBreakpoints()

  const isHoverAllowedForBp = useMemo(
    () => bp === 'lg' || bp === 'xl' || bp === 'md',
    [bp]
  )
  const isHoverAllowed = useMemo(
    () => isReadyForHover && isHoverAllowedForBp,
    [isReadyForHover, isHoverAllowedForBp]
  )

  const $waitingForAllowedHover = useRef(false)
  const $hasRunAnimation = useRef(false)

  const handleHoverPerson = useCallback(
    (personId) => (ev) => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current.delayCb)
      }
      debounceRef.current = {
        personId,
        delayCb: setTimeout(() => {
          setHoveredPersonId(personId)
          moveImgToMouse({ event: ev, element: $personImage.current })
          debounceRef.current = null
        }, 150),
      }
    },
    []
  )

  const handleMouseMove = useCallback(
    (ev) => {
      if (!hoveredPersonId) return null
      if (!ticking.current) {
        window.requestAnimationFrame(() => {
          moveImgToMouse({ event: ev, element: $personImage.current })
          ticking.current = false
        })
      }
      ticking.current = true
    },
    [hoveredPersonId]
  )

  const handleMouseLeave = useCallback(() => {
    if (debounceRef.current) {
      clearTimeout(debounceRef.current.delayCb)
    }

    timelines.current.hide = hidePersonImage({
      element: $personImage.current,
      onComplete: () => setHoveredPersonId(null),
    })
  }, [])

  const handleOnMouseEnter = useCallback(
    (ev) => {
      if (!isHoverAllowed) {
        $waitingForAllowedHover.current = true
        return
      }
      timelines.current.show = showPersonImage({
        element: $personImage.current,
      })
    },
    [isHoverAllowed]
  )

  useEffect(() => {
    if (!isHoverAllowed || !$waitingForAllowedHover.current) {
      return
    }

    handleOnMouseEnter()
    $waitingForAllowedHover.current = false
  }, [isHoverAllowed, handleOnMouseEnter])

  useEffect(() => {
    ScrollTrigger.clearScrollMemory()
  }, [])

  useEffect(() => {
    if (team.length) {
      setSectionEntry()
    }
  }, [team])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animation.canStart && animation.isReady) {
      $hasRunAnimation.current = true
      setSectionEntry(true)
      return
    }
  }, [animation.isReady, animation.canStart])

  useEffect(() => {
    if (!animation.canPlay) {
      return
    }

    const tl = animateSectionEntry({
      onComplete: () => {
        setIsReadyForHover(true)
      },
    })
    return () => tl && tl.kill()
  }, [animation.canPlay])

  useEffect(() => {
    if (!isHoverAllowedForBp) {
      timelines.current.hide = hidePersonImage({
        element: $personImage.current,
      })
    }
  }, [isHoverAllowedForBp])

  useEffect(() => {
    const tl = timelines.current
    return () => {
      Object.values(tl).map((t) => t && t.kill())
    }
  }, [])

  useEffect(() => {
    return () => {
      if (!debounceRef.current) {
        return
      }
      clearTimeout(debounceRef.current.delayCb)
    }
  }, [animation])

  const flbImageSource = '/images/person-fallback.jpg'

  return (
    <>
      <div className="person-image" ref={$personImage}>
        {team.map((p) => (
          <div
            key={p.id}
            data-person-id-img={p.id}
            className={`person-image__item ${
              p.id === hoveredPersonId ? 'is-visible' : ''
            }`}
          >
            <PrismicBreakpointPicture
              className="img"
              fallbackAlt={`${p.data?.full_name?.text} — ${p.data?.role?.text}`}
              fallbackImg={{
                ...(p.data?.picture?.url
                  ? p.data?.picture
                  : { url: flbImageSource }),
                width: 300,
                height: 368,
              }}
              sources={{
                all: p.data?.picture_list_all?.fixed
                  ? p.data?.picture_list_all
                  : { fixed: { src: flbImageSource } },
              }}
              fallbackColor="#f5f5f5"
            />
          </div>
        ))}
      </div>
      <div
        onMouseMove={handleMouseMove}
        onMouseLeave={handleMouseLeave}
        onMouseEnter={handleOnMouseEnter}
        role="presentation"
      >
        <ul className="mosaic mosaic--team">
          {team.map((person, index) => {
            const personId = person.id
            const personData = person.data
            const isPersonHovered = personId === hoveredPersonId
            const opacityClass =
              !hoveredPersonId || isPersonHovered ? '' : 'c-alpha-4'
            return (
              <li
                data-person-id={personId}
                role="presentation"
                key={index}
                onMouseEnter={handleHoverPerson(personId)}
              >
                <p className={`mb-1 ft-default-m-small ${opacityClass}`}>
                  {personData.first_name?.text}
                </p>
                <p className={`mb-0 ft-secondary c-gray-600 ${opacityClass}`}>
                  {personData.role?.text}
                </p>
              </li>
            )
          })}
        </ul>
      </div>
    </>
  )
}

export default memo(ListTeam)
