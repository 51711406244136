import { animateContactEntry, animateTitleEntry, setContactEntry, setTitleEntry } from "./common"

const selectors = {
  title: {
    title: '.tpl-about__title',
    alias: '.tpl-about__alias',
    line: '.tpl-about__title-line'
  }
}

const setScrollEntry = (show) => {
  setContactEntry(show)
}

const setEntry = (show) => {
  setTitleEntry(
    selectors.title, 
    selectors.title.line.substring(1, selectors.title.line.length),
    show
  )
}

const animateScrollEntry = () => {
  const tl = []
  tl.push(animateContactEntry({
    start: '150% bottom',
    end: '200% bottom'
  }), 0)

  return tl
}

const animateEntry = () => {
  const tl = []
  tl.push(animateTitleEntry(selectors.title))
  return tl
}


export {
  setEntry,
  animateEntry,
  animateScrollEntry,
  setScrollEntry
}