import gsap from 'gsap'
import { easeOutCubic } from '../../utils/constants/eases'
import { gsapParams } from '../../utils/page-animations/common'

const selectors = {
  wrapper: '.tpl-about__section-about',
  content: '.tpl-about__section-about .section-about__body',
}

const animateSectionEntry = (props) => {
  const onComplete = props && props.onComplete ? props.onComplete : () => {}
  const tl = gsap
    .timeline({
      onComplete,
    })
    .pause()
  gsap.set(selectors.wrapper, { scaleX: 0 })
  gsap.set(selectors.content, { opacity: 0, translateY: 60 })
  tl.to(
    selectors.wrapper,
    { scaleX: 1, duration: 0.6, ease: easeOutCubic },
    0.3
  )
  tl.resume()

  return tl
}

const animateSectionContentEntry = () => {
  const tl = gsap.timeline({
    scrollTrigger: {
      trigger: selectors.wrapper,
      start: 'top 80%',
      end: '30% center',
      once: true,
    },
  })
  tl.to('.section-about__body', {
    ...gsapParams.fadeInUp,
    stagger: 0.05,
    duration: 0.5,
  })

  return tl
}

export { animateSectionEntry, animateSectionContentEntry }
