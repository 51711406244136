import React, { memo, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import LocalizedTransitionLink from '../LocalizedTransitionLink'
import { animateEntry, setEntry } from './animations'

const JoinUs = ({ ctaLink, animation }) => {
  const { t } = useTranslation(['home', 'common'])
  const $hasRunAnimation = useRef(false)

  useEffect(() => {
    setEntry()
  }, [])

  useEffect(() => {
    if ($hasRunAnimation.current) {
      return
    }

    if (!animation.canStart && animation.isReady) {
      $hasRunAnimation.current = true
      setEntry(true)
      return
    }
  }, [animation.isReady, animation.canStart])

  useEffect(() => {
    if (!animation.canPlay) {
      return
    }

    const tl = animateEntry({
      onComplete: () => {
        $hasRunAnimation.current = true
      },
    })

    return () => tl && tl.kill()
  }, [animation.canPlay])

  return (
    <div id="join-us" className="container ">
      <div className="col col-lg-14 col-xl-12 col-lg-offset-1 mb-md-8 mb-lg-0 mb-b-lg-2">
        <div className="row join-us">
          <div className="col col-md-4 col-lg-3 col-lg-offset-1">
            <div className="join-us__title mt-md-2 ft-default ft-400 max-width-250px">
              {t('home:careers:title')}
            </div>
            <div className="join-us__cta">
              <LocalizedTransitionLink
                to={ctaLink}
                className="link link--primary link--arrow mt-1"
              >
                <span>{t('home:careers:cta')}</span>
              </LocalizedTransitionLink>
            </div>
            <div className="hide-mobile mt-6 mb-2 mb-md-0 col-md-8 col-lg-10">
              <img
                className="join-us__img"
                src="/images/careers.svg"
                alt="Source Careers"
              />
            </div>
          </div>
          <div className="col col-md-8 col-lg-8 col-lg-offset-1 col-xl-offset-0 mt-md-0 mt-3">
            <h2 className="join-us__desc h5 ft-400 ft-f-default mb-5 mb-lg-5 ">
              {t('home:careers:desc')}
            </h2>
            <div className="join-us__mosaic mosaic mosaic-lg-2 mosaic-md-2 mosaic--gap-24">
              <div>
                <h3 className="ft-f-default ft-default ft-400">
                  {t('home:careers:item_1:title')}
                </h3>
                <p className="mb-0 mt-0 mt-md-2 ft-400 ft-default-m-small c-gray-650">
                  {t('home:careers:item_1:desc')}
                </p>
              </div>
              <div>
                <h3 className="ft-f-default ft-default ft-400">
                  {t('home:careers:item_2:title')}
                </h3>
                <p className="mb-0 mt-0 mt-md-2 ft-400 ft-default-m-small c-gray-650">
                  {t('home:careers:item_2:desc')}
                </p>
              </div>
            </div>
          </div>
          <div className="join-us__img-mobile hide-desktop col col-xs-3 mt-4">
            <img
              src="/images/careers.svg"
              alt="Source Careers"
              width="193"
              height="131"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(JoinUs)
